import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../layout"
import SEO from "../seo"
import Img from "gatsby-image"
import { format } from "date-fns"

export default ({ data, location }) => {
  const { mdx } = data // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx
  
  return (
    <Layout>
      <SEO title={frontmatter.title}/>
      <div>
        <div className="row container mx-auto">
          <div className="col-12 px-0 mb-3 d-flex back-btn-container">
            {"< "}
            <Link to="/fundraisers" className="back-btn"> 
              <h5 className="m-0 pl-2">
                {`Back to fundraisers`}
              </h5>
            </Link>
          </div>
          <div className="col-12 px-0 mt-1">
            <Img
              fluid={frontmatter.coverImg.childImageSharp.fluid}
              style={{ maxHeight: "37vh" }}
            />
            <h1 className="mt-3 mb-0 text-center">
              {frontmatter.title}
            </h1>
            <h5 className="mt-0 mb-2 text-center">
              {format(new Date(frontmatter.date), "iii, dd MMM yyyy")}
            </h5>
            <p className="mb-5 text-center">
              {frontmatter.desc}
            </p>
            <div className={`lato article`}>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        title
        desc
        date
        coverImg {
          childImageSharp {
            fluid(maxWidth: 1000) {
              # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
